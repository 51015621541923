import bowser from 'bowser';
import {
  socketEpic, betsEpic, soundEpic, analyticsEpic, betsReducer, statisticsReducer,
} from './store';
import { gameResultsClassName, } from './styles';

const isHandheld = bowser.mobile || bowser.tablet;
const noComponent = () => Promise.resolve({ default: () => null, });

export default () => ({
  components: {
    GameBoard: () => import('./views/GameBoard/GameBoard'),
    VideoOverlay: noComponent,
    ProgressBar: noComponent,
    Payouts: () => import('./views/GameDrawer/widgets/Payouts/Payouts'),
    Statistics: () => import('./views/GameDrawer/widgets/Statistics/Statistics'),
    ResultMessage: () => import('./components/ResultMessage/ResultMessage'),
  },
  store: {
    epics: [ socketEpic, betsEpic, soundEpic, analyticsEpic, ],
    reducers: {
      bets: betsReducer,
      statistics: statisticsReducer,
    },
  },
  props: {
    BoardToggle: {
      style: {
        height: '100%',
      },
    },
    GameResults: {
      className: isHandheld && gameResultsClassName,
    },
  },
  gameBoardHeight: 30,
  desktopBoardToggle: true,
  hideLeftSideDrawer: true,
});
