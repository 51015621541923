import {
  compose,
  applySpec,
  filter,
  equals,
  keys,
  allPass,
  concat,
  of,
  contains,
  range,
  modulo,
  add,
  not,
  complement,
  __,
} from 'ramda';

import { getMatches, } from '../components/BetGrid/utils';
import { BET_COLOURS, } from '../constants/betColors';
import { isNeighborBet, } from '../components/Racetrack/utils';
import { BET_INDEX_LABEL_MAP, } from '../constants/betLabels';

export const COLOR = {
  RED: 'RED',
  BLACK: 'BLACK',
  GREEN: 'GREEN',
};

export const NUMBERS = {
  REGULAR_WHEEL_ORDER: [
    100,
    132,
    115,
    119,
    104,
    121,
    102,
    125,
    117,
    134,
    106,
    127,
    113,
    136,
    111,
    130,
    108,
    123,
    110,
    105,
    124,
    116,
    133,
    101,
    120,
    114,
    131,
    109,
    122,
    118,
    129,
    107,
    128,
    112,
    135,
    103,
    126,
  ],
  AMERICAN_WHEEL_ORDER: [
    100,
    128,
    109,
    126,
    130,
    111,
    107,
    120,
    132,
    117,
    105,
    122,
    134,
    115,
    103,
    124,
    136,
    113,
    101,
    137,
    127,
    110,
    125,
    129,
    112,
    108,
    119,
    131,
    118,
    106,
    121,
    133,
    116,
    104,
    123,
    135,
    114,
    102,
  ],
  RED: [ 101, 103, 105, 107, 109, 112, 114, 116, 118, 119, 121, 123, 125, 127, 130, 132, 134, 136, ],
  BLACK: [ 102, 104, 106, 108, 110, 111, 113, 115, 117, 120, 122, 124, 126, 128, 129, 131, 133, 135, ],
  GREEN: [ 100, 137, ],
};

export const extractTableBetNumber = (number) => {
  const winningNumber = number - 100;
  return winningNumber === 37 ? '00' : winningNumber;
};

export const getColorByIndex = (index) => {
  const idx = index < 100 ? 100 + index : index;
  if (NUMBERS.RED.includes(idx)) return COLOR.RED;
  if (NUMBERS.BLACK.includes(idx)) return COLOR.BLACK;
  if (NUMBERS.GREEN.includes(idx)) return COLOR.GREEN;
  return null;
};

// return color by index
export const getNumberColor = (num) => {
  const color = getColorByIndex(num < 100 ? num + 100 : num);
  switch (color) {
  case COLOR.BLACK:
    return BET_COLOURS.BLACK;
  case COLOR.RED:
    return BET_COLOURS.RED;
  case COLOR.GREEN:
    return BET_COLOURS.GREEN;
  default:
    return null;
  }
};

// example : const neighbors = get_neighbour_bets(100, 2);
export const getNeighbourNumbers = ({ index, nrOfNeighbors = 1, american = false, }) => {
  const BETS = american ? NUMBERS.AMERICAN_WHEEL_ORDER : NUMBERS.REGULAR_WHEEL_ORDER;
  const middleIndex = BETS.indexOf(index);
  const numberOfBets = 2 * nrOfNeighbors + 1;
  const result = [];

  let startIndex = middleIndex - nrOfNeighbors;
  if (startIndex < 0) {
    startIndex = BETS.length + startIndex;
  }

  for (let i = 0; i < numberOfBets; i++) {
    if (startIndex === BETS.length) {
      startIndex = 0;
    }

    result.push(BETS[startIndex]);
    startIndex++;
  }
  return result;
};

const EVEN = 901;
const ODD = 904;
const FIRST_HALF = 900;
const SECOND_HALF = 905;
const FIRST_DOZEN = 800;
const SECOND_DOZEN = 801;
const THIRD_DOZEN = 802;
const RED = 903;
const BLACK = 902;
const FIRST_ROW = 700;
const SECOND_ROW = 701;
const THIRD_ROW = 702;

export const getNumberIndex = add(100);

const isZeroOrDoubleZero = contains(__, [ 0, 37, ]);

const isOdd = modulo(__, 2);
const isEven = compose(
  not,
  isOdd
);

const isFirstHalf = contains(__, range(1, 19));
const isSecondHalf = contains(__, range(19, 37));

const isFirstDozen = contains(__, range(1, 13));
const isSecondDozen = contains(__, range(13, 25));
const isThirdDozen = contains(__, range(25, 37));

const isRow = (type) => compose(
  contains(__, getMatches(`index-${type}`)),
  getNumberIndex
);

const isColor = (color) => compose(
  contains(__, color),
  getNumberIndex
);

export const getCurrentHighlights = (number) => concat(
  of(getNumberIndex(number)),
  compose(
    keys,
    filter(equals(true)),
    applySpec({
      [EVEN]: allPass([ isEven, complement(isZeroOrDoubleZero), ]),
      [ODD]: allPass([ isOdd, complement(isZeroOrDoubleZero), ]),
      [RED]: isColor(NUMBERS.RED),
      [BLACK]: isColor(NUMBERS.BLACK),
      [FIRST_HALF]: isFirstHalf,
      [SECOND_HALF]: isSecondHalf,
      [FIRST_DOZEN]: isFirstDozen,
      [SECOND_DOZEN]: isSecondDozen,
      [THIRD_DOZEN]: isThirdDozen,
      [FIRST_ROW]: isRow(FIRST_ROW),
      [SECOND_ROW]: isRow(SECOND_ROW),
      [THIRD_ROW]: isRow(THIRD_ROW),
    })
  )(number)
);

export const getBetLabel = (index) => {
  if (isNeighborBet(index)) return 'Neighbor';

  if (index >= 100 && index <= 137) return 'Straight';
  if (index >= 200 && index <= 292) return 'Split';
  if (index >= 300 && index <= 301) return 'Trio';
  if (index >= 400 && index <= 422) return 'Corner';
  if (index >= 500 && index <= 592) return 'Street';
  if (index >= 600 && index <= 690) return 'Line';

  return BET_INDEX_LABEL_MAP[index];
};
