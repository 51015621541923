export const BET_INDEX_LABEL_MAP = {
  700: '3-36',
  701: '2-35',
  702: '1-34',
  800: '1st 12',
  801: '2nd 12',
  802: '3rd 12',
  900: '1-18',
  901: 'EVEN',
  902: 'BLACK',
  903: 'RED',
  904: 'ODD',
  905: '19-36',
  1000: 'Tiers',
  1001: 'Orphelins',
  1002: 'Voisins',
  1003: 'Jeu Zero',
};
