export const LAST_NUMBERS_LABEL = 'Last numbers';
export const STATS_WHEEL_LABEL = 'Radar';

export const BET_SOURCE_STATISTICS = 'statistics';
export const HOT_AND_COLD_LABEL_MAP = {
  cold: 'Cold numbers',
  hot: 'Hot numbers',
};

export const ROU_STATISTICS_BET = 'rou_statistics_bet';
export const ROU_BET = 'rou_bet';
