import { flip, prop, } from 'ramda';
import { BET_MATCHES, } from './constants';

export const getMatches = flip(prop)(BET_MATCHES);

export const addValue = (bet1, bet2) => ({
  index: bet1.index,
  value: bet1.value + bet2.value,
});

export const getBetIndex = (e) => {
  const el = e.target.closest('[id^=index-]');

  if (!el) return null;

  return Number(el.id.replace('index-', ''));
};
