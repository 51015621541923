import { ofType, combineEpics, } from 'redux-observable';
import { filter, map, pluck, } from 'rxjs/operators';
import { selectors, actions, } from '@ezugi/bootstrap';
import {
  equals, values, head, prop, pipe, not,
} from 'ramda';

import betActions from '../../actions/bets';
import {
  BET_SOURCE_STATISTICS,
  ROU_STATISTICS_BET,
  ROU_BET,
} from '../../constants/analytics';
import { getBetLabel, } from '../../../util/betUtils';

const { frenchBet, simpleBet, neighborBet, } = betActions;
const {
  analyticsActions: { analytics, },
} = actions;
const { roundIdSelector, tableIdSelector, clientIdSelector, } = selectors;

/**
 * Send an analytics event when a player places a bet on
 * Statistics (StatsWheel, Last numbers, Hot and Cold or any other Statistics section)
 * The bets are filtered through the 'source' prop BET_SOURCE_STATISTICS
 */
const betOnStatisticsEpic = (action$, state$) => action$.pipe(
  ofType(simpleBet.add),
  pluck('payload'),
  map(pipe(values, head)),
  filter(pipe(prop('source'), equals(BET_SOURCE_STATISTICS))),
  map((payload) => analytics.push({
    event: ROU_STATISTICS_BET,
    roundId: roundIdSelector(state$.value),
    tableId: tableIdSelector(state$.value),
    clientId: clientIdSelector(state$.value),
    betType: payload.label || getBetLabel(payload.index),
  }))
);

/**
 * Send an analytics event when the player places a bet on the Board
 * or Racetrack with the appropriate betType (eg: StraightUp/Voisins, Dozens)
 */
const betEpic = (action$, state$) => action$.pipe(
  ofType(frenchBet.add, neighborBet.add, simpleBet.add),
  pluck('payload'),
  map(pipe(values, head)),
  filter(pipe(prop('source'), pipe(equals(BET_SOURCE_STATISTICS), not))),
  map((payload) => analytics.push({
    event: ROU_BET,
    roundId: roundIdSelector(state$.value),
    tableId: tableIdSelector(state$.value),
    clientId: clientIdSelector(state$.value),
    betType: payload.label || getBetLabel(payload.index),
  }))
);

export default combineEpics(betOnStatisticsEpic, betEpic);
